import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import Fallback from './Fallback';

// interface RootErrorBoundaryProps {
//   children?: ReactNode;
// }

// const RootErrorBoundary: FC<RootErrorBoundaryProps> = ({ children }) => {
const RootErrorBoundary = ({ children }) => {

  return (
    <ErrorBoundary
      FallbackComponent={Fallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {children}
    </ErrorBoundary>
  );

};

RootErrorBoundary.propTypes = {
  children: PropTypes.node as unknown as React.Validator<React.ReactNode>,
};

export default RootErrorBoundary;
